import { useContext, useState } from 'react';
import { AppBar, Box, IconButton, makeStyles, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from "react-i18next";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { useRouter } from 'next/router';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import UserContext from "../src/UserContext";
import ImageContainer from "./images/ImageContainer";
import LoginDialog from './dialogs/LoginDialog';


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'white',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#1B67AC',
    },
    iconButton: {
        marginRight: theme.spacing(2),
        color: '#1B67AC',
    },
    title: {
        color: '#1B67AC',
        fontWeight: 'bold',
        paddingLeft: '10px',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    logo: {
        maxHeight: 60,
        cursor: 'pointer',
    }
}));

export default function PrimarySearchAppBar() {
    const classes = useStyles();
    const router = useRouter();
    const [t, i18n] = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [langAnchorEl, setLangAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [openLogin, setOpenLogin] = useState(false);
    let { keycloakstate, } = useContext(UserContext);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    let redirectHome = () => {
        router.push('/');
    };

    let redirectAdd = () => {
        router.push('/questions/add');
    };

    let redirectSearch = () => {
        router.push('/questions/search');
    };

    let redirectFavorites = () => {
        router.push('/favorites');
    };
    let redirectManagement = () => {
        router.push('/management');
    };
    let redirectParametricFiles = () => {
        router.push('/parametricFiles');
    };

    const login = async () => {
        router.push('/logIn');
    };

    const logout = async () => {
        sessionStorage.removeItem('authentication');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('userSession');

        let currentURL = window.location.protocol + "//" + window.location.host;

        if (process.env.KEYCLOAK_VERSION === '11') {
            keycloakstate.keycloak.logout({ redirectUri: currentURL });
        } else if (process.env.KEYCLOAK_VERSION === '22') {
            const keycloakJson = (
                await import('../public/keycloak/' + process.env.keycloak)
            ).default;
            const url =
                keycloakJson['auth-server-url'] +
                'realms/' +
                keycloakJson['realm'] +
                '/protocol/openid-connect/logout?post_logout_redirect_uri=' +
                currentURL + '&client_id=' + keycloakJson['resource'];

            window.location.href = url;
        }
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleClickLanguages = (e) => {
        setLangAnchorEl(e.currentTarget);
    };

    const handleCloseLanguages = () => {
        setLangAnchorEl(null);
    };

    const handleChooseGreek = () => {
        i18n.changeLanguage('gr');
        setLangAnchorEl(null);
    };

    const handleChooseEnglish = () => {
        i18n.changeLanguage('en');
        setLangAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {keycloakstate?.authenticated === true ?
                <MenuItem onClick={redirectManagement}>{t('manageQuestions')}</MenuItem>
                : ""
            }
            {keycloakstate?.authenticated === true && JSON.parse(sessionStorage.getItem('userSession'))?.isAdmin ?
                <MenuItem onClick={redirectParametricFiles}>{t('parametricFiles')}</MenuItem>
                : ""
            }
            <MenuItem onClick={logout}>{t('logOut')}</MenuItem>
        </Menu>
    );

    const renderLanguageMenu = (
        <Menu
            anchorEl={langAnchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(langAnchorEl)}
            onClose={handleCloseLanguages}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem onClick={handleChooseGreek}>
                <Box width="100%" display="flex" justifyContent="space-between">
                    Ελληνικά
                    <Box ml="1em" width="24px">
                        <ImageContainer src={"/static/gr-flag.png"} alt={t('aria.grFlagImg')} />
                    </Box>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleChooseEnglish}>
                <Box width="100%" display="flex" justifyContent="space-between">
                    English
                    <Box ml="1em" width="24px">
                        <ImageContainer src={"/static/en-flag.png"} alt={t('aria.enFlagImg')} />
                    </Box>
                </Box>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={redirectHome}>
                <IconButton aria-label="Αρχική" className={classes.iconButton}>
                    <HomeOutlinedIcon />
                </IconButton>
                <p>{t('home')}</p>
            </MenuItem>
            <MenuItem onClick={redirectSearch}>
                <IconButton aria-label="Αναζήτηση" className={classes.iconButton}>
                    <SearchOutlinedIcon />
                </IconButton>
                <p>{t('search')}</p>
            </MenuItem>
            <MenuItem onClick={redirectFavorites}>
                <IconButton aria-label="Αγαπημένα" className={classes.iconButton}>
                    <FavoriteBorderOutlinedIcon />
                </IconButton>
                <p>{t('favorites')}</p>
            </MenuItem>
            <MenuItem onClick={redirectAdd}>
                <IconButton aria-label="Νέο Ερώτημα" className={classes.iconButton}>
                    <AddIcon />
                </IconButton>
                <p>{t('newQuestion')}</p>
            </MenuItem>
            <MenuItem onClick={handleClickLanguages}>
                <IconButton title={t('changeLanguage')} className={classes.iconButton}>
                    <Box width="24px">
                        <ImageContainer src={"/static/" + i18n.language + "-flag.png"} alt={t('aria.' + i18n.language + 'FlagImg')} />
                    </Box>
                </IconButton>
                <p>{t('changeLanguage')}</p>
            </MenuItem>
            {keycloakstate?.authenticated !== true ?
                <MenuItem onClick={() => setOpenLogin(true)}>
                    <IconButton aria-label="Σύνδεση/Εγγραφή" className={classes.iconButton}>
                        <ExitToAppIcon />
                    </IconButton>
                    <p>{t('logInSignUp')}</p>
                </MenuItem>
                : ''
            }
            {keycloakstate?.authenticated === true ?
                <MenuItem onClick={handleProfileMenuOpen}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        className={classes.iconButton}
                    >
                        <AccountCircle />
                    </IconButton>
                    {JSON.parse(sessionStorage.getItem('userSession'))?.lastName + " " + JSON.parse(sessionStorage.getItem('userSession'))?.firstName}
                </MenuItem>
                : ''
            }
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <img onClick={redirectHome} src={`/static/${process.env.chamberName}/chamberLogo.png`} alt="logo"
                        className={classes.logo} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {process.env.chamberNameGr}
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <MenuItem onClick={redirectHome} className={classes.menuButton}>
                            <p>{t('home')}</p>
                        </MenuItem>
                        <MenuItem onClick={redirectSearch} className={classes.menuButton}>
                            <p>{t('search')}</p>
                        </MenuItem>
                        <MenuItem onClick={redirectFavorites} className={classes.menuButton}>
                            <p>{t('favorites')}</p>
                        </MenuItem>
                        <MenuItem className={classes.menuButton} onClick={redirectAdd}>
                            <p>{t('newQuestion')}</p>
                        </MenuItem>
                        {keycloakstate?.authenticated !== true ?
                            <MenuItem className={classes.menuButton} onClick={() => setOpenLogin(true)}>
                                <IconButton title={t('logInSignUp')} className={classes.iconButton} aria-label='login signup'>
                                    <ExitToAppIcon />
                                </IconButton>
                            </MenuItem>
                            : ''
                        }
                        {keycloakstate?.authenticated === true ?
                            <MenuItem onClick={handleProfileMenuOpen} className={classes.menuButton}>
                                <IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    className={classes.iconButton}
                                >
                                    <AccountCircle />
                                </IconButton>
                                {JSON.parse(sessionStorage.getItem('userSession'))?.lastName + " " + JSON.parse(sessionStorage.getItem('userSession'))?.firstName}
                            </MenuItem>
                            : ''
                        }
                        <MenuItem className={classes.menuButton} onClick={handleClickLanguages}>
                            <IconButton title={t('changeLanguage')} className={classes.iconButton} aria-label='change language'>
                                <Box width="24px">
                                    <ImageContainer src={"/static/" + i18n.language + "-flag.png"} alt={t('aria.' + i18n.language + 'FlagImg')} />
                                </Box>
                            </IconButton>
                        </MenuItem>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            className={classes.iconButton}
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {renderLanguageMenu}
            <LoginDialog
                open={openLogin}
                handleClickCancel={() => setOpenLogin(false)}
                login={login}
            />
        </div>
    );
}
