import { makeStyles, Dialog, DialogActions, DialogTitle, Box, DialogContent, DialogContentText } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import DialogLoginButton from '../buttons/DialogLoginButton';

//make the popup appear at the center
const useStyles = makeStyles({
    container: {
        alignItems: "center",
        justifyContent: "center"
    },
});

export default function LoginDialog({ open, handleClickCancel, login }) {
    const [t,] = useTranslation();
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClickCancel} classes={{ container: classes.container }}>
            <DialogTitle >
                {t('login.signIn')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('login.loginText1')}
                </DialogContentText>
                <DialogContentText>
                    {t('login.loginText2')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box width="100%" m={2} display="flex" justifyContent="center" alignItems="center">
                    <DialogLoginButton onClick={login} />
                </Box>
            </DialogActions>
        </Dialog>
    );
}
