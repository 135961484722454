import '../styles/globals.css';
import { useEffect, useState, useMemo } from 'react';
import Head from 'next/head';
import { StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import { AuthProvider, ProtectRoute } from '../src/AuthKeycloakProvider';
import UserContext from '../src/UserContext';
import { appWithTranslation } from '../i18n';
import styles from '../styles/Layout.module.css';
import * as Sentry from '@sentry/node';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
    });
}

function MyApp({ Component, pageProps, err }) {

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    const [keycloakstate, setKeycloakstate] = useState({
        keycloak: null,
        authenticated: false,
        token: null,
        userName: null,
        user_id: null,
        roles: null
    });

    const value = useMemo(() => ({ keycloakstate, setKeycloakstate }), [keycloakstate, setKeycloakstate]);

    return (
        <UserContext.Provider value={value}>
            <AuthProvider>
                <html lang="en" />
                <ProtectRoute>
                    <div className={styles.container}>
                        <Head>
                            <title>Online Σύμβουλος του Επιχειρηματία</title>
                            {/*<link rel="icon" href="/favicon.ico"/>*/}
                            <link rel="icon" href={`/static/${process.env.chamberName}/favicon.png`} />
                        </Head>

                        <StylesProvider injectFirst> {/*materialUI styles applied BEFORE ours*/}
                            <div className={styles.topBar}>
                                <TopBar />
                            </div>
                            <main className={styles.main}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Component {...pageProps} err={err} />
                                </MuiPickersUtilsProvider>
                            </main>
                            <Footer />
                        </StylesProvider>

                    </div>
                </ProtectRoute>
            </AuthProvider>
        </UserContext.Provider>
    );
}

export default appWithTranslation(MyApp);

