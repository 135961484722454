import { Box, Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useRouter } from 'next/router';
import ImageContainer from './images/ImageContainer';
import styles from '../styles/Footer.module.css';

export default function Footer() {
    const router = useRouter();
    const [t,] = useTranslation();

    let redirectPolicy = () => {
        router.push('/policy');
    };

    let redirectTerms = () => {
        router.push('/terms');
    };

    return (
        <Box
            component="footer"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={styles.footer}
            paddingY="1em"
            overflow="hidden"
        >
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item sm={11} md={4} >
                    <Box display="flex" justifyContent="center">
                        <Box>
                            <Box component="a" onClick={redirectPolicy}>
                                {t('policy.title')}
                            </Box>
                            {" / "}
                            <Box component="a" onClick={redirectTerms}>
                                {t('terms.title')}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={8} md={4} >
                    <ImageContainer src={`/static/${process.env.chamberName}/banner.jpg`} alt={t('aria.espaImg')} />
                </Grid>
                <Grid item sm={11} md={4} >
                    <Box display="flex" justifyContent="center">
                        <a
                            href="https://www.knowledge.gr/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Knowledge AE
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}