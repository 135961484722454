import { Box } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Button from './ButtonBase';
import styles from '../../styles/SubmitButton.module.css';

export default function DialogLoginButton(props) {
    const [t,] = useTranslation();
    return (
        <Button
            oneTime
            variant="contained"
            className={styles.container}
            onClick={props.onClick}
            size="large"
        >
            <Box fontWeight="bold" component="span">
                {t('buttons.SIGN_IN')}
            </Box>
        </Button>
    );
}
